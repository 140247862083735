import { gsap } from 'gsap';

export const revealTextBlocks = () => {
  let lines = gsap.utils.toArray(
    '.life-cycle__text-blocks-wrapper .horizontal-line'
  );
  let textBlocks = gsap.utils.toArray(
    '.life-cycle__text-blocks-wrapper__text-block'
  );

  let tl = gsap.timeline({
    defaults: {
      duration: 0.8,
      ease: 'power1.out'
    },
    scrollTrigger: {
      trigger: '.life-cycle__text-blocks-wrapper',
      start: 'top 75%'
    }
  });

  tl.from(lines[0], { scaleX: 0, transformOrigin: 'left center' })
    .from(
      textBlocks,
      {
        autoAlpha: 0,
        y: 50,
        stagger: 0.2
      },
      '-=0.4'
    )
    .from(lines[1], { scaleX: 0, transformOrigin: 'left center' }, '-=0.4');

  return () => {
    tl.pause(0).kill(true);
    tl.scrollTrigger?.kill(true);
  };
};
