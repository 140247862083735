const transitionDefault = {
  type: 'tween',
  duration: 0.8,
  ease: 'easeOut'
};

export const revealHeadline = {
  initial: {
    opacity: 0,
    y: 40
  },
  animate: {
    opacity: 1,
    y: 0,
    transition: transitionDefault
  }
};

export const revealDescription = {
  initial: {
    opacity: 0,
    y: 80
  },
  animate: {
    opacity: 1,
    y: 0,
    transition: {
      ...transitionDefault,
      delay: 0.6
    }
  }
};
