import React from 'react';

// External Components
import { Paragraph, Box, Section } from '@thepuzzlers/pieces';

// Local Components
import {
  GridSpacer,
  DividerLine,
  IllustrationBlockWithNoise,
  MarkedHeading
} from 'components';

// Animations
import { revealTextBlocks } from './animations';

import { convertApiHtmlText } from 'graphqlHooks/helper';

export const LifeCycle = ({
  staticData: { images },
  data: { caption, description, headline, text_block_one, text_block_two }
}) => {
  React.useEffect(() => {
    const killReveal = revealTextBlocks();

    return killReveal;
  }, []);

  const textBlocks = [{ text: text_block_one }, { text: text_block_two }];

  return (
    // Markup
    <Section id="sustainability-page__life-cycle">
      <Spacer position={0} />
      <Forklift data={images[0]} />
      <Wrench data={images[1]} />
      <Stool data={images[2]} />
      <Headline data={headline} />
      <Spacer position={1} />
      <Caption data={caption} />
      <Description data={description} />
      <TextBlocks data={textBlocks} />
    </Section>
  );
};

// Elements

// Images Components

const Forklift = ({ data }) => (
  <IllustrationBlock
    data={data}
    bg="primary"
    sx={{
      boxShadow: 500,
      gridColumn: [
        '1/span 7',
        '2/span 5',
        '4/span 9',
        '1/span 6',
        '2/span 6',
        '3/span 5'
      ],
      gridRow: 2
    }}
  />
);

const Wrench = ({ data }) => (
  <IllustrationBlock
    data={data}
    bg="secondary"
    sx={{
      boxShadow: 300,
      gridColumn: [
        '6/13',
        '7/span 5',
        '13/span 9',
        '9/span 6',
        '9/span 6',
        '9/span 5'
      ],
      gridRow: [3, 3, 3, 2, 2, 2],
      mt: ['5.6rem', '6.8rem', '8rem', '13.7rem', '18.8rem', '22.3rem']
    }}
  />
);

const Stool = ({ data }) => (
  <IllustrationBlock
    data={data}
    bg="primary"
    sx={{
      boxShadow: 700,
      gridColumn: [
        '2/span 9',
        '3/span 7',
        '7/span 13',
        '17/25',
        '17/25',
        '16/span 7'
      ],
      mt: ['5.6rem', '8.8rem', '8rem', '2.4rem', '2.5rem', '1.5rem']
    }}
  />
);

// Text Components

const Headline = ({ data }) => (
  <MarkedHeading
    as="h2"
    data={convertApiHtmlText(data.html)}
    sx={{
      fontSize: ['3.2rem', '3.4rem', '4rem', '3.2rem', '3.8rem', '4.8rem'],
      gridColumn: [
        '1/13',
        '2/span 10',
        '2/span 22',
        '2/span 22',
        '4/span 18',
        '4/span 17'
      ],
      lineHeight: 1.25,
      mt: ['11.1rem', '11.1rem', '12.4rem', '7.5rem', '12.3rem', '11.8rem'],
      textAlign: 'center',
      '& > span:first-of-type': {
        '&::before': {
          width: ['100%', '100%', '100%', '115%', '115%', '115%']
        }
      }
    }}
  />
);

const Caption = ({ data }) => (
  <MarkedHeading
    as="h3"
    data={convertApiHtmlText(data.html)}
    sx={{
      fontSize: ['2.1rem', '2.3rem', '2.4rem', '1.8rem', '2.2rem', '2.4rem'],
      fontStyle: ['italic', 'italic', 'normal', 'normal', 'normal', 'normal'],
      gridColumn: [
        '1/13',
        '1/13',
        '2/span 19',
        '1/span 10',
        '1/span 10',
        '1/span 8'
      ],
      lineHeight: 1.6
    }}
  />
);

const Description = ({ data }) => (
  <Paragraph
    dangerouslySetInnerHTML={{
      __html: data
    }}
    sx={{
      fontFamily: 'primary.normal',
      fontSize: ['1.7rem', '2rem', '2rem', '1.6rem', '1.7rem', '1.8rem'],
      fontFeatureSettings: "'pnum' on, 'onum' on",
      gridColumn: ['1/13', '1/13', '2/span 22', '13/25', '13/25', '12/25'],
      lineHeight: 1.5,
      mt: ['7.8rem', '6.8rem', '6.4rem', 0, 0, 0]
    }}
  />
);

const TextBlocks = ({ data }) => (
  <Box
    className="life-cycle__text-blocks-wrapper"
    sx={{
      gridColumn: ['1/13', '1/13', '1/25', '1/25', '1/25', '1/25'],
      mt: ['9.8rem', '12.4rem', '10.8rem', '15.7rem', '15.4rem', '19.1rem']
    }}>
    <DividerLine />
    <Box
      sx={{
        display: 'grid',
        gap: [
          '5.6rem',
          '6.4rem',
          '6.4rem',
          '3.2rem 6.6rem',
          '7.1rem',
          '9.7rem'
        ],
        gridTemplateColumns: [
          '1fr',
          '1fr',
          '1fr',
          '1fr 1fr',
          'repeat(2, 1fr)',
          'repeat(2, 1fr)'
        ],
        p: [
          '5.6rem 0',
          '6.4rem 4.4rem',
          '6.4rem 9.1rem',
          '3.2rem 0',
          '4.8rem 8rem',
          '4.1rem 16rem'
        ]
      }}>
      {data.map((block) => (
        <Paragraph
          key={block.text}
          className="life-cycle__text-blocks-wrapper__text-block"
          sx={{
            fontFamily: 'primary.normal',
            fontSize: [
              '1.6rem',
              '1.7rem',
              '2rem',
              '1.5rem',
              '1.5rem',
              '1.6rem'
            ],
            fontFeatureSettings: "'pnum' on, 'onum' on",
            lineHeight: 1.5
          }}>
          {block.text}
        </Paragraph>
      ))}
    </Box>
    <DividerLine />
  </Box>
);

// Reusable Component

const IllustrationBlock = ({ data, bg, sx }) => (
  <IllustrationBlockWithNoise
    data={data}
    bg={bg}
    sx={{
      alignSelf: 'start',
      borderRadius: 'card',
      p: ['1.2rem', '1.9rem', '2rem', '1.2rem', '1.8rem', '2rem'],
      ...sx
    }}
  />
);

// Spacer

const Spacer = ({ position }) => {
  const heights = [
    ['18.7rem', '22.7rem', '14.8rem', '22.8rem', '42.7rem', '42.4rem'],
    ['11.5rem', '9.2rem', '6.2rem', '7.1rem', '6.7rem', '12.1rem']
  ];

  return <GridSpacer height={heights[position]} />;
};
