import React from 'react';

// External Components
import { Heading, Paragraph } from '@thepuzzlers/pieces';
import { usePageContext } from 'gatsby-theme-thepuzzlers-core';

// Local Components
import { TealNoiseSection, GridSpacer } from 'components';

// Animations
import { revealHeadline, revealDescription } from './animations';

// Helper function
import { convertApiHtmlText } from 'graphqlHooks/helper';

export const Header = ({ staticData: { headline }, data: { description } }) => {
  const { locale } = usePageContext();

  return (
    // Markup
    <ContentWrapper>
      <Spacer position={0} />
      {locale === 'de' ? (
        <HeadlineDE data={headline} />
      ) : (
        <HeadlineEN data={headline} />
      )}
      <Description data={description} />
      <Spacer position={1} />
    </ContentWrapper>
  );
};

// Elements

const ContentWrapper = ({ children }) => (
  <TealNoiseSection
    as="header"
    id="sustainability-page__header"
    // Animation values
    initial="initial"
    animate="animate">
    {children}
  </TealNoiseSection>
);

const HeadlineDE = ({ data }) => (
  <Headline
    data={data}
    sx={{
      fontSize: ['4.2rem', '5.6rem', '6.8rem', '3.8rem', '5rem', '6.4rem'],
      gridColumn: [
        '1/13',
        '1/13',
        '2/span 22',
        '1/span 22',
        '2/span 20',
        '2/span 20'
      ]
    }}
    spanSx={{ mr: [0, 0, 0, 0, '2rem', '2rem'] }}
  />
);

const HeadlineEN = ({ data }) => (
  <Headline
    data={data}
    sx={{
      fontSize: ['4rem', '5.6rem', '6.8rem', '3.6rem', '5rem', '6.4rem'],
      gridColumn: ['1/13', '1/13', '2/span 22', '1/25', '1/25', '1/span 23']
    }}
  />
);

const Description = ({ data }) => (
  <Paragraph
    dangerouslySetInnerHTML={{
      __html: convertApiHtmlText(data.html)
    }}
    // Animation value
    variants={revealDescription}
    sx={{
      color: 'background',
      fontFamily: 'primary.normal',
      fontSize: ['1.7rem', '2rem', '2rem', '1.6rem', '1.7rem', '1.8rem'],
      fontFeatureSettings: "'pnum' on, 'onum' on",
      gridColumn: [
        '1/13',
        '1/13',
        '2/span 19',
        '10/25',
        '11/span 12',
        '12/span 11'
      ],
      lineHeight: 1.5,
      mt: ['6.6rem', '8rem', '7.2rem', '4.8rem', '6.8rem', '7.1rem'],
      '& > span': {
        color: 'secondary',
        fontFamily: 'primary.italic'
      }
    }}
  />
);

const Headline = ({ data, sx, spanSx }) => (
  <Heading
    as="h1"
    dangerouslySetInnerHTML={{
      __html: data
    }}
    // Animation value
    variants={revealHeadline}
    sx={{
      color: 'background',
      fontFamily: 'primary.normal',
      lineHeight: 1.15,
      textTransform: 'uppercase',
      '& > .headline__align-right': {
        display: ['inline', 'inline', 'inline', 'block', 'block', 'block'],
        textAlign: [null, null, null, 'right', 'right', 'right'],
        ...spanSx
      },
      '& > .lime-text': {
        fontFamily: 'primary.italic',
        animation: '0.8s ease-out 0.8s lightToLimeText',
        animationFillMode: 'forwards'
      },
      ...sx
    }}
  />
);

// Spacer

// Added in between sections inside header for bg noise
const Spacer = ({ position }) => {
  const heights = [
    ['12.7rem', '20.6rem', '27.3rem', '14.1rem', '20.8rem', '22.7rem'],
    ['12.4rem', '24.1rem', '25.4rem', '10.4rem', '19.5rem', '26rem']
  ];

  return <GridSpacer height={heights[position]} />;
};
