import { gsap } from 'gsap';

export const revealListLines = () => {
  let lines = gsap.utils.toArray(
    '.benefits-of-timber__list-wrapper .horizontal-line'
  );

  return lines.forEach((line) => {
    let tween = gsap.from(line, {
      scaleX: 0,
      transformOrigin: 'left center',
      duration: 1.2,
      scrollTrigger: {
        trigger: line,
        start: 'top 80%'
      }
    });

    return () => {
      tween.kill(true);
      tween.scrollTrigger?.kill(true);
    };
  });
};

export const revealListItems = () => {
  let listItems = gsap.utils.toArray(
    '.benefits-of-timber__list-wrapper__list-item'
  );

  return listItems.forEach((item) => {
    let tween = gsap.from(item, {
      autoAlpha: 0,
      y: 60,
      duration: 0.8,
      scrollTrigger: {
        trigger: item,
        start: 'top 80%'
      }
    });

    return () => {
      tween.kill(true);
      tween.scrollTrigger?.kill(true);
    };
  });
};

export const headlineImageAnimation = () => {
  const imageContainer = '.benefit-of-timber__headline-image-container';

  const timeline = gsap.timeline({
    defaults: { duration: 0.8 },
    scrollTrigger: {
      trigger: imageContainer,
      start: 'top bottom',
      end: 'center center',
      scrub: 0.5
    }
  });

  timeline.from(
    imageContainer,
    {
      yPercent: 50
    },
    '<'
  );

  return () => {
    timeline.kill();
  };
};
