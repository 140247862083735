import React from 'react';

// External Components
import { Section } from '@thepuzzlers/pieces';

// Local Components
import {
  TextBlockWrapper,
  Vector,
  GridSpacer,
  MarkedHeading
} from 'components';

// Assets
import crown from 'assets/svg/process/crown.svg';

// Helper Function
import { convertApiHtmlText } from 'graphqlHooks/helper';

export const Impact = ({ data: { headline, text_block } }) => {
  return (
    // Markup
    <Section id="sustainability-page__impact">
      <Spacer position={0} />
      <Decoration />
      <Headline data={headline} />
      <TextBlocks data={text_block} />
      <Spacer position={1} />
    </Section>
  );
};

// Elements
const Headline = ({ data }) => (
  <MarkedHeading
    as="h2"
    data={convertApiHtmlText(data.html)}
    sx={{
      fontSize: ['2.4rem', '2.6rem', '3rem', '2.4rem', '2.8rem', '3.2rem'],
      gridColumn: [
        '1/13',
        '1/span 11',
        '2/span 18',
        '2/span 22',
        '3/span 17',
        '4/span 15'
      ],
      gridRow: 3,
      lineHeight: 1.25
    }}
  />
);

const TextBlocks = ({ data }) => {
  const textBlocks = [{ text: data }];
  return (
    <TextBlockWrapper
      className="impact__text-blocks"
      data={textBlocks}
      sx={{
        gap: ['3.2rem', '4.8rem', '6.4rem', '6.7rem', '5rem', '7rem'],
        gridColumn: [
          '1/13',
          '1/span 10',
          '2/span 17',
          '2/span 22',
          '3/span 17',
          '4/span 15'
        ],
        gridRow: 4,
        mt: ['5.6rem', '7.2rem', '7rem', '4rem', '5.8rem', '7.2rem']
      }}
      textSx={{
        fontSize: ['1.6rem', '1.7rem', '1.8rem', '1.5rem', '1.5rem', '1.6rem']
      }}
    />
  );
};

const Decoration = () => (
  <Vector
    src={crown}
    alt=""
    sx={{
      gridRow: 2,
      position: 'relative',
      left: ['15.6rem', '17rem', '22.2rem', '18.2rem', '26.8rem', '37rem'],
      width: ['3.4rem', '3.4rem', '4.8rem', '3.6rem', '4.6rem', '6.2rem']
    }}
  />
);

const Spacer = ({ position }) => {
  const heights = [
    ['16.1rem', '7.4rem', '16rem', '14.1rem', '21.1rem', '27.5rem'],
    ['24rem', '13.8rem', '23.2rem', '14.4rem', '32.6rem', '29.4rem']
  ];

  return <GridSpacer height={heights[position]} />;
};
