import React from 'react';

// External Components
import { Paragraph, Box, FlexWrapper, Section } from '@thepuzzlers/pieces';

// Local Components
import {
  DividerLine,
  MarkedHeading,
  AspectRatioImageContainer
} from 'components';

import {
  revealListLines,
  revealListItems,
  headlineImageAnimation
} from './animations';

// Helper function
import { convertApiHtmlText } from 'graphqlHooks/helper';

export const BenefitsOfTimber = ({
  staticData: { image },
  data: { headline, benefit_list }
}) => {
  React.useEffect(() => {
    const killLines = revealListLines();
    const killListItems = revealListItems();

    return () => {
      killLines;
      killListItems;
    };
  }, []);

  return (
    // Markup
    <Section id="sustainability-page__benefits-of-timber">
      <Image data={image} />
      <Headline data={headline} />
      {benefit_list && benefit_list.length !== 0 && (
        // remove this logic after backend translation for array_of_object is finish
        <ListWrapper data={benefit_list} />
      )}
    </Section>
  );
};

// Elements

const Image = ({ data }) => {
  React.useEffect(() => {
    return headlineImageAnimation();
  }, []);
  return (
    // AdditionalBox needed for animation purposes
    <Box
      className="benefit-of-timber__headline-image-container"
      sx={{
        gridColumn: ['1/13', '1/13', '1/25', '1/25', '2/span 22', '2/span 22'],
        mt: ['-60%', '-60%', '-37.5%', '-23.5%', '-23.5%', '-23.5%'],
        // Animation Value
        overflow: 'hidden'
      }}>
      <AspectRatioImageContainer
        {...data}
        className="benefit-of-timber__headline-image"
        aspectRatios={[
          1 / 1.2,
          1 / 1.2,
          1 / 0.75,
          1 / 0.47,
          1 / 0.47,
          1 / 0.47
        ]}
      />
    </Box>
  );
};

const Headline = ({ data }) => (
  <MarkedHeading
    as="h2"
    data={convertApiHtmlText(data.html)}
    sx={{
      fontSize: ['3.4rem', '3.8rem', '4.4rem', '3.2rem', '4.4rem', '4.8rem'],
      gridColumn: ['1/13', '1/13', '1/25', '1/25', '2/span 22', '2/span 22'],
      lineHeight: 1.15,
      mt: ['6.4rem', '9.8rem', '9.8rem', '6.4rem', '10.8rem', '11.6rem'],
      textTransform: 'uppercase'
    }}
  />
);

const ListWrapper = ({ data }) => (
  <Box
    className="benefits-of-timber__list-wrapper"
    sx={{
      gridColumn: ['1/13', '1/13', '1/25', '1/25', '2/span 22', '2/span 22'],
      mt: ['10.8rem', '11.4rem', '9.8rem', '10.7rem', '10.2rem', '12rem']
    }}>
    <DividerLine />
    {data.map((item, index) => (
      <React.Fragment key={index}>
        {index !== 0 && <DividerLine />}
        <ListItem data={item.data} />
      </React.Fragment>
    ))}
    <DividerLine />
  </Box>
);

const ListItem = ({ data: { title, description_text } }) => (
  <FlexWrapper
    className="benefits-of-timber__list-wrapper__list-item"
    sx={{
      flexDirection: ['column', 'column', 'column', 'row', 'row', 'row'],
      justifyContent: 'space-between',
      p: [
        '5.6rem 0',
        '6.4rem 0',
        '5.6rem 3rem',
        '6.4rem 0',
        '8rem 4.2rem 8rem 0',
        '12.8rem 5.4rem 12.8rem 0'
      ]
    }}>
    <MarkedHeading
      as="h3"
      data={convertApiHtmlText(title.html)}
      sx={{
        fontSize: ['2.4rem', '2.6rem', '2.8rem', '2.2rem', '2.4rem', '2.8rem'],
        lineHeight: 1.15,
        width: ['100%', '38.6rem', '40.8rem', '28.2rem', '37rem', '41.6rem']
      }}
    />
    <Paragraph
      sx={{
        fontFamily: 'primary.normal',
        fontSize: ['1.7rem', '2rem', '2rem', '1.6rem', '1.7rem', '1.8rem'],
        lineHeight: 1.5,
        mt: ['4rem', '4.8rem', '3.2rem', 0, 0, 0],
        width: ['100%', '100%', '100%', '34rem', '45.4rem', '52.4rem']
      }}
      dangerouslySetInnerHTML={{
        __html: convertApiHtmlText(description_text.html)
      }}
    />
  </FlexWrapper>
);
