import React from 'react';

import { SEO } from 'gatsby-theme-thepuzzlers-intl';
import { injectIntl } from 'gatsby-theme-thepuzzlers-intl';

// Local Components
import {
  useSustainabilityPageData,
  Header,
  ProductionCycle,
  BenefitsOfTimber,
  LifeCycle,
  Impact
} from 'sections/sustainabilityPage';

import { useKisSustainabilityPageData } from 'graphqlHooks';

const Sustainability = () => {
  const { seo, header, benefitsOfTimber, lifeCycle } =
    useSustainabilityPageData();

  const {
    header: cmsHeader,
    production_cycle: cmsProductionCycle,
    benefits_of_timber: cmsBenefitOfTimber,
    life_cycle: cmsLifeCycle,
    impact: cmsImpact
  } = useKisSustainabilityPageData();

  return (
    <>
      <SEO {...seo} />
      <Header staticData={header} data={cmsHeader} />
      <ProductionCycle data={cmsProductionCycle} />
      <BenefitsOfTimber
        staticData={benefitsOfTimber}
        data={cmsBenefitOfTimber}
      />
      <LifeCycle staticData={lifeCycle} data={cmsLifeCycle} />
      <Impact data={cmsImpact} />
    </>
  );
};

export default injectIntl(Sustainability);
