import React from 'react';

// External Components
import { Heading, Paragraph, Box, FlexWrapper } from '@thepuzzlers/pieces';

// Local Components
import {
  TealNoiseSection,
  GridSpacer,
  HorizontalLine,
  Vector,
  TextBlockWrapper
} from 'components';

// Assets
import arrow from 'assets/svg/lime-arrow.svg';
import arrowDown from 'assets/svg/arrow-down_lime.svg';
import illustrationSmall from 'assets/svg/productionCycle/product-cycle_small.svg';
import illustrationBig from 'assets/svg/productionCycle/product-cycle_big.svg';

// Helper function
import { convertApiHtmlText } from 'graphqlHooks/helper';

export const ProductionCycle = ({
  data: {
    description,
    emission_headline,
    emission_textblock_one,
    emission_textblock_two,
    headline,
    step_one_description,
    step_one_title,
    step_three_description,
    step_three_title,
    step_two_description,
    step_two_title
  }
}) => {
  // at the moment we keep it like this until all text block type is come from the CMS
  const emissionTextBlocks = [
    { text: emission_textblock_one },
    { text: emission_textblock_two }
  ];

  const steps = [
    { title: step_one_title, description: step_one_description },
    { title: step_two_title, description: step_two_description },
    { title: step_three_title, description: step_three_description }
  ];

  return (
    // Markup
    <ContentWrapper>
      <Steps data={steps} />
      <IllustrationSmall />
      <IllustrationBig />
      <HeadlineAndDescription headline={headline} description={description} />
      <ArrowDown />
      <EmissionsHeadline data={emission_headline} />
      <TextBlocks data={emissionTextBlocks} />
      <Spacer />
    </ContentWrapper>
  );
};

// Elements

const ContentWrapper = ({ children }) => (
  <TealNoiseSection id="sustainability-page__production-cycle">
    {children}
  </TealNoiseSection>
);

const Steps = ({ data }) => (
  <Box
    className="production-cycle__steps-wrapper"
    sx={{
      gridColumn: [
        '1/13',
        '1/13',
        '2/span 20',
        '1/span 20',
        '2/span 14',
        '2/span 11'
      ],
      gridRow: 1
    }}>
    <HorizontalLine />
    <FlexWrapper
      className="steps-wrapper__steps"
      sx={{
        alignItems: 'center',
        flexDirection: ['column', 'column', 'row', 'row', 'row', 'row'],
        py: ['2.6rem', '4.2rem', '2.2rem', '2.2rem', '2.4rem', '2.4rem']
      }}>
      {data.map((step, index) => (
        <React.Fragment key={step.title}>
          {index !== 0 && <StepArrow />}
          <StepBlock data={step} />
        </React.Fragment>
      ))}
    </FlexWrapper>
    <HorizontalLine />
  </Box>
);

const StepBlock = ({ data: { title, description } }) => (
  <Box
    className="steps__step-block"
    sx={{ maxWidth: ['100%', '100%', '26%', '29%', '29%', '28%'] }}>
    <StepText bold>{title}</StepText>
    <StepText
      sx={{
        mt: ['0.9rem', '0.9rem', '1rem', '1rem', '1rem', '1rem']
      }}>
      {description}
    </StepText>
  </Box>
);

const StepArrow = () => (
  <Vector
    className="sustainability-page__steps__arrow"
    alt=""
    src={arrow}
    sx={{
      m: [
        '2rem auto',
        '4.2rem auto',
        '0 3.8rem 0 2.2rem',
        '0 3.8rem 0 3rem',
        '0 3.8rem 0 3rem',
        '0 3.8rem 0 2.4rem'
      ],
      transform: [
        null,
        null,
        'rotate(-90deg)',
        'rotate(-90deg)',
        'rotate(-90deg)',
        'rotate(-90deg)'
      ],
      width: ['1.8rem', '2.2rem', '1.5rem', '1.8rem', '1.8rem', '1.6rem']
    }}
  />
);

const IllustrationSmall = () => (
  <Vector
    className="production-cycle__illustration-small"
    alt=""
    src={illustrationSmall}
    sx={{
      display: ['block', 'block', 'none', 'none', 'none', 'none'],
      gridColumn: ['1/13', '1/13', null, null, null, null],
      gridRow: 2,
      mt: ['5.2rem', '5.9rem', null, null, null, null],
      // Need to add aspect ratio to help make scroll trigger has correct markers
      aspectRatio: '1/1.97'
    }}
  />
);

const IllustrationBig = () => (
  <Vector
    className="production-cycle__illustration-big"
    alt=""
    src={illustrationBig}
    sx={{
      alignSelf: 'start',
      display: ['none', 'none', 'block', 'block', 'block', 'block'],
      gridColumn: [null, null, '9/25', '12/25', '12/span 12', '13/span 11'],
      gridRow: 2,
      mt: [null, null, '6.9rem', '8rem', '2.8rem', '1.2rem'],
      // Need to add aspect ratio to help make scroll trigger has correct markers
      aspectRatio: '1.122/1'
    }}
  />
);

const HeadlineAndDescription = ({ headline, description }) => (
  <Box
    className="production-cycle__headline-and-description"
    sx={{
      gridColumn: [
        '1/13',
        '1/span 10',
        '2/span 15',
        '1/span 13',
        '2/span 10',
        '2/span 8'
      ],
      gridRow: [3, 3, 3, 2, 2, 2],
      mt: ['6.6rem', '7.6rem', '4.9rem', '31.1rem', '34.3rem', '35rem']
    }}>
    <Headline
      data={headline}
      sx={{
        width: ['100%', '100%', '100%', '68%', '100%', '100%']
      }}
    />
    <Paragraph
      sx={{
        color: 'background',
        fontFamily: 'primary.normal',
        fontSize: ['1.7rem', '2rem', '2rem', '1.6rem', '1.7rem', '1.8rem'],
        lineHeight: 1.5,
        mt: ['1.9rem', '1.9rem', '2.4rem', '1.2rem', '2.4rem', '2.4rem']
      }}>
      {description}
    </Paragraph>
  </Box>
);

const ArrowDown = () => (
  <Vector
    src={arrowDown}
    alt=""
    sx={{
      gridColumn: [
        '9/span 2',
        '10/span 2',
        '17/span 2',
        '13/span 2',
        '11/span 2',
        '10/span 2'
      ],
      gridRow: [4, 4, 4, 3, 3, 3],
      justifySelf: ['end', 'center', 'start', 'center', 'center', 'center'],
      mt: ['6.2rem', '5.4rem', '3.6rem', '7.5rem', '4.2rem', '4.5rem'],
      width: ['3.6rem', '4rem', '4.2rem', '3.3rem', '4.2rem', '5.2rem']
    }}
  />
);

const EmissionsHeadline = ({ data }) => (
  <Headline
    data={data}
    sx={{
      gridColumn: [
        '1/13',
        '1/13',
        '2/span 20',
        '4/span 20',
        '6/span 17',
        '7/span 15'
      ],
      gridRow: [5, 5, 5, 4, 4, 4],
      mt: ['9.3rem', '9.3rem', '10.4rem', '9.4rem', '9.2rem', '10.7rem']
    }}
  />
);

const TextBlocks = ({ data }) => (
  <TextBlockWrapper
    className="production-cycle__text-blocks"
    data={data}
    lightText
    sx={{
      gap: ['7.2rem', '7.2rem', '6.3rem', '6.6rem', '5.2rem', '7rem'],
      gridColumn: [
        '1/13',
        '1/span 10',
        '2/span 17',
        '4/span 20',
        '6/span 17',
        '7/span 15'
      ],
      gridRow: [6, 6, 6, 5, 5, 5],
      mt: ['6.4rem', '7.2rem', '4.5rem', '3.6rem', '5.8rem', '7.2rem']
    }}
  />
);

// Reusable Components

const StepText = ({ children, bold, sx }) => (
  <Paragraph
    sx={{
      color: 'background',
      fontFamily: bold ? 'body.bold' : 'body.normal',
      fontSize: ['1.5rem', '1.7rem', '1.4rem', '1.4rem', '1.4rem', '1.4rem'],
      lineHeight: 1,
      textAlign: ['center', 'center', 'left', 'left', 'left', 'left'],
      ...sx
    }}>
    {children}
  </Paragraph>
);

const Headline = ({ data, sx }) => (
  <Heading
    as="h2"
    dangerouslySetInnerHTML={{
      __html: convertApiHtmlText(data.html)
    }}
    sx={{
      color: 'background',
      fontFamily: 'primary.normal',
      fontSize: ['2.8rem', '3.2rem', '3.4rem', '2.4rem', '2.8rem', '3.2rem'],
      lineHeight: 1.25,
      '& > span': { color: 'secondary', fontFamily: 'primary.italic' },
      ...sx
    }}
  />
);

// Spacer

const Spacer = () => (
  <GridSpacer
    height={['35.4rem', '53.6rem', '47.7rem', '28.7rem', '44rem', '47.8rem']}
  />
);
