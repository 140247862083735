import { graphql, useStaticQuery } from 'gatsby';
import { usePageContext } from 'gatsby-theme-thepuzzlers-core';

export const useSustainabilityPageData = () => {
  const { locale } = usePageContext();
  const data = useStaticQuery(graphql`
    query {
      en: sustainabilityPageDataJson {
        seo {
          title
          description
          shortTitle
          shortDescription
          url
        }
        header {
          headline
        }
        benefitsOfTimber {
          image {
            src {
              childImageSharp {
                gatsbyImageData
              }
            }
            alt
          }
        }
        lifeCycle {
          images {
            src {
              childImageSharp {
                gatsbyImageData
              }
            }
            alt
          }
        }
      }
      de: deSustainabilityPageDataJson {
        seo {
          title
          description
          shortTitle
          shortDescription
          url
        }
        header {
          headline
        }
        benefitsOfTimber {
          image {
            src {
              childImageSharp {
                gatsbyImageData
              }
            }
            alt
          }
        }
        lifeCycle {
          images {
            src {
              childImageSharp {
                gatsbyImageData
              }
            }
            alt
          }
        }
      }
    }
  `);

  return data[locale];
};
